import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import styled, { useTheme } from 'styled-components';
import { useContactModal } from 'context/Contact';

import axios from 'axios';
import { useForm, FormProvider } from 'react-hook-form';
import TextInput from 'components/fields/TextInput';
import TextArea from 'components/fields/TextArea';
import ChipSelect from 'components/fields/ChipSelect';
import ChipSingleSelect from 'components/fields/ChipSingleSelect';
import Button from 'components/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const Form = styled.form`
  padding: 1.5rem 1.5rem 2rem;
  display: grid;
  gap: 3rem;
  max-width: 44.25rem;
  margin: 0 auto;
  align-self: center;

  ${({ theme: { mq } }) => mq.lg`
    padding: 2.5rem;
  `};
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 3.25rem;
  line-height: 1;

  ${({ theme: { mq } }) => mq.md`
    font-size: 5.625rem;
  `}
`;

const Instructions = styled.p`
  margin: 1rem 0 0;
  padding: 0;

  ${({ theme: { mq } }) => mq.md`
    font-size: 1.5rem;
  `}
`;

const ButtonWrapper = styled(Button)`
  display: block;
  width: 100%;
  padding: 0.75rem;
`;

const formSchema = z.object({
  name: z.string().min(3, { message: 'This field is required.' }),
  email: z.string().email({ message: 'Enter a valid email.' }),
  message: z.string().optional(),
  interest: z.array(z.string()).optional(),
  budget: z.string().optional()
});

type TFormData = z.infer<typeof formSchema>;

const defaultValues = {
  name: '',
  email: '',
  message: ''
};

const ContactForm: React.FC = () => {
  const { contactModalOpen: open, showContactModal: close } = useContactModal();

  const theme = useTheme();
  const [submitError, setSubmitError] = useState('');
  const methods = useForm<TFormData>({
    defaultValues,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(formSchema)
  });

  const {
    formState: { isSubmitting, errors, isValid, isSubmitSuccessful },
    reset
  } = methods;

  useEffect(() => {
    if (!open) {
      setTimeout(reset, 1000);
    }
  }, [open, isSubmitSuccessful, reset]);

  const handleContactSubmit = async (values: Record<string, any>) => {
    if (isSubmitSuccessful) {
      return;
    }
    try {
      await axios.post('/api/send-contact-email', {
        ...values
      });
    } catch (err) {
      if (err instanceof Error) {
        setSubmitError(err.message as string);
      }
      console.error('Unepected error', err);
    }
  };

  const handleSubmissionText = (
    submitError: string,
    isSubmitSuccessful: boolean,
    isValid: boolean
  ) => {
    if (submitError) {
      return 'Sending failed. Please try again later.';
    } else if (isSubmitSuccessful && isValid) {
      return 'Sent!';
    } else {
      return 'Send';
    }
  };

  const handleSubmissionColor = (
    submitError: string,
    isSubmitSuccessful: boolean,
    isValid: boolean
  ) => {
    if (submitError) {
      return theme?.themeColors?.danger;
    } else if (isSubmitSuccessful && isValid) {
      return theme?.themeColors?.success;
    } else {
      return '';
    }
  };

  return (
    <Modal open={open} close={close}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(handleContactSubmit)}>
          {isSubmitSuccessful ? (
            <div>
              <Title>Message received</Title>
              <Instructions>
                Thank you for reaching out! We are reviewing your message and will respond shortly.
              </Instructions>
            </div>
          ) : (
            <>
              <div>
                <Title>Get in touch</Title>
                <Instructions>
                  Fill out this form (with as much detail as possible!) and we’lll get back to you
                  within 48 hours
                </Instructions>
              </div>

              <TextInput fieldName="name" placeholder="Name *" />
              <TextInput fieldName="email" placeholder="Email *" />
              <TextArea fieldName="message" placeholder="Message" />

              <ChipSelect
                label="I'm interested in"
                name="interest"
                options={[
                  { value: 'ecommerce', label: 'ECommerce' },
                  { value: 'rebrand', label: 'Rebrand' },
                  { value: 'sass-platform', label: 'SaaS Platform' },
                  { value: 'service-integration', label: 'Service Integration' },
                  { value: 'cms', label: 'CMS' },
                  { value: 'mobile-app', label: 'Mobile App' }
                ]}
              />

              <ChipSingleSelect
                label="My budget is"
                name="budget"
                options={[
                  { value: '<20k', label: '<20k' },
                  { value: '20-50k', label: '20k - 50k' },
                  { value: '50-150k', label: '50k - 150k' },
                  { value: '>150k', label: '>150k' }
                ]}
              />

              <ButtonWrapper
                disabled={
                  !isValid || isSubmitting || !!Object.keys(errors).length || isSubmitSuccessful
                }
                outline={!isValid || isSubmitSuccessful}
                color={handleSubmissionColor(submitError, isSubmitSuccessful, isValid)}
                type="submit"
                size="md"
              >
                {handleSubmissionText(submitError, isSubmitSuccessful, isValid)}
              </ButtonWrapper>
            </>
          )}
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ContactForm;
