import { theme } from '@refract-ui/core';
import { DefaultTheme } from 'styled-components';
import { refract, gfx } from '../shared';

const plumeTheme = {
  name: 'plume',
  ...gfx,
  ...theme({
    ...refract,
    colors: {
      black: '#333333',
      purple: '#6269ff',
      green: '#288ba2'
    },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: theme?.colors?.black,
      fg: theme?.colors?.white,
      primary: theme?.colors?.purple,
      secondary: theme?.colors?.green,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
} as DefaultTheme;

export default plumeTheme;

export const plumeLight = {
  name: 'plumeLight',
  ...gfx,
  ...theme({
    ...refract,
    colors: {
      purple: '#585EE5',
      green: '#288ba2'
    },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: theme?.colors?.purple,
      fg: theme?.colors?.white,
      primary: theme?.colors?.black,
      secondary: theme?.colors?.white,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
} as DefaultTheme;
export const plumeSlider = {
  name: 'plumeSlider',
  ...gfx,
  ...theme({
    ...refract,
    colors: {
      purple: '#6269ff',
      green: '#288ba2'
    },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: '#302E41',
      fg: theme?.colors?.white,
      primary: theme?.colors?.purple,
      secondary: theme?.colors?.green,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
} as DefaultTheme;
