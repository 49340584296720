import Analytics from 'analytics';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// @ts-ignore
import segmentPlugin from '@analytics/segment';
import googleAnalytics from '@analytics/google-analytics';

const segmentKey = process.env.NEXT_PUBLIC_ANALYTICS_ID;
const gaKey = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;
const reporter =
  segmentKey && gaKey
    ? Analytics({
        app: 'gfx-website-2022',
        plugins: [
          segmentPlugin({
            writeKey: segmentKey as string
          }),
          googleAnalytics({
            measurementIds: [gaKey as string]
          })
        ]
      })
    : null;
export default reporter;
