import React from 'react';
import { GlobalStyles as RefractStyles } from '@refract-ui/core';
import { createGlobalStyle, ThemeProvider, type DefaultTheme } from 'styled-components';

import themes from '.';
const GfxStyles = createGlobalStyle`
  html, body, #__next {
    display: flex;
    min-height: 100%;
    flex: 1 0 auto;
    flex-direction: column;
    color: ${({ theme: { themeColors } }) => themeColors.fg};
    background: ${({ theme: { themeColors } }) => themeColors.bg};
  }

  h1, h2, h3, h4, h5, h6, li {
    color: ${({ theme: { themeColors } }) => themeColors.fg};
  }

  body.noscroll {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  ul {
    padding: 0;
  }

  ul{
    padding: 0;
  }

  p {
   &:empty {
      display: none;
    }
  }

  b {
    &:empty {
      display: none;
   }
  }

  a {
    font-weight: 700;
    color: ${({ theme: { themeColors } }) => themeColors.fg};
    text-decoration: none;

    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }
`;

interface GfxGlobalStyles {
  children: React.ReactNode;
  theme?: DefaultTheme;
}

const GfxGlobalStyles: React.FC<GfxGlobalStyles> = ({ children, theme = themes.dark }) => {
  return (
    <ThemeProvider theme={theme as DefaultTheme}>
      <RefractStyles />
      <GfxStyles />
      {children}
    </ThemeProvider>
  );
};

export default React.memo(GfxGlobalStyles);
