import React from 'react';
import Icon from 'components/Icons/Icon';
import styled, { css } from 'styled-components';
import { IconNames } from 'components/Icons/IconList';
import FormattedText from 'components/FormattedText';

interface Button {
  outline?: boolean;
  disabled?: boolean;
  color?: string;
  size: 'sm' | 'md';
  type: 'button' | 'submit' | 'reset';
  leadingIcon?: IconNames;
  trailingIcon?: IconNames;
  children: React.ReactNode;
  onClick?: () => void;
}

const StyledButton = styled.button<Button>`
  border-radius: 94px;
  padding: 1rem 1.875rem;

  background: ${props => {
    if (props.outline) {
      return 'transparent';
    }
    return props.color || props.theme.themeColors.fg;
  }};

  p {
    color: ${props => {
      if (!props.outline) {
        return props.theme.themeColors.bg;
      }
    }};
  }

  border: ${props =>
    props.outline ? css`1px solid ${props.color || props.theme.themeColors.fg}` : 'none'};

  transition: all 0.25s ease-in-out 0s;

  :hover {
    opacity: 0.65;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  :focus {
    outline: 0;
  }

  :focus-visible {
    outline: 0.125rem solid #0070f3;
  }
`;

const StyledFormattedText = styled(FormattedText)`
  && {
    display: inline;
    margin-bottom: 0px;
  }
`;

const LeadingIcon = styled(Icon)`
  margin-right: 1rem;
`;
const TrailingIcon = styled(Icon)`
  margin-left: 1rem;
`;

const Button: React.FC<Button> = ({
  outline,
  disabled,
  color,
  size,
  type,
  leadingIcon,
  trailingIcon,
  onClick,
  ...props
}) => {
  const { children } = props;

  return (
    <StyledButton
      outline={outline}
      disabled={disabled}
      color={color}
      size={size}
      type={type}
      onClick={onClick}
      {...props}
    >
      {leadingIcon ? <LeadingIcon name={leadingIcon} /> : undefined}
      <StyledFormattedText pFormat={size === 'md' ? 'default' : 'monoRegular16'}>
        {children}
      </StyledFormattedText>
      {trailingIcon ? <TrailingIcon name={trailingIcon} /> : undefined}
    </StyledButton>
  );
};

export default Button;
