interface Variant {
  [key: string]: any;
}

const variants = {
  sansRegular64: {
    xs: `
    font-family: SpaceGroteskLight;
    font-size: 2.25rem;
    line-height: 1.167;
    letter-spacing: normal;
    `,
    sm: `
    font-size: 2.25rem;
    line-height: 1.167;
    `,
    md: `
      line-height: 1;
      font-size: 4rem;
    `
  },
  sansRegular52: {
    xs: `
    font-family: SpaceGroteskLight;
    line-height: 1.281;
    font-size: 1.875rem;
    letter-spacing: normal;
    `,
    md: `
      line-height: 1.286;
      font-size: 3.25rem;
    `
  },
  sansRegular36: {
    xs: `
    font-family: SpaceGroteskLight;
    line-height: 1.417;
    font-size: 1.5rem;
    letter-spacing: normal;`,
    md: `
      line-height: 1.167;
      font-size: 2.25rem;
    `
  },
  sansRegular30: {
    xs: `
    font-family: SpaceGroteskLight;
    font-size: 1.5rem;
    line-height: 1.235;
    letter-spacing: normal;
    `,
    md: `
      line-height: 1.235;
      font-size: 1.875rem;
    `
  },
  default: {
    xs: `
      font-family: SpaceGroteskLight;
      font-size: 1.125rem;
      line-height: 1.444;
      letter-spacing: normal;
    `,
    sm: `
      font-family: SpaceGroteskLight;
      font-size: 1.125rem;
      line-height: 1.444;
    `,
    md: `
      font-family: SpaceGroteskLight;
      font-size: 1.5rem;
      line-height: 1.416;
    `,
    lg: `
      font-family: SpaceGroteskLight;
      font-size: 1.5rem;
      line-height: 1.416;
    `,
    xl: `
      font-family: SpaceGroteskLight;
      line-height: 1.416;
      font-size: 1.5rem;
    `,
    xxl: `
      font-family: SpaceGroteskLight;
      line-height: 1.417;
      font-size: 1.5rem;
    `
  },
  sansRegular24: {
    xs: `
    font-family: SpaceGroteskLight;
    font-size: 1.125rem;
    line-height: 1.444;
    letter-spacing: normal;
    `,
    md: `
      line-height: 1.417;
      font-size: 1.5rem;
    `
  },
  sansBold24: {
    xs: `
    font-family: SpaceGroteskBold;
    font-size: 1.125rem;
    line-height: 1.278;
    letter-spacing: normal;`,
    md: `
      line-height: 1.417;
      font-size: 1.5rem;
    `
  },
  sansRegular18: {
    xs: `
    font-family: SpaceGroteskLight;
    font-size: 1.125rem;
    line-height: 1.278;
    letter-spacing: normal;
  `
  },
  sansBold18: {
    xs: `
    font-family: SpaceGroteskBold;
    font-size: 1.125rem;
    line-height: 1.278;
    letter-spacing: normal;
  `
  },
  sansRegular12: {
    xs: `
    font-family: SpaceGroteskLight;
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: normal;
  `
  },
  monoRegular24: {
    xs: `
    font-family: SpaceMonoRegular;
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: normal;
    `,
    md: `
    font-size: 1.5rem;
    `
  },
  monoRegular18: {
    xs: `
    font-family: SpaceMonoRegular;
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: normal;
    `
  },
  monoRegular16: {
    xs: `
    font-family: SpaceMonoRegular;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
  `
  },
  monoBold16: {
    xs: `
    font-family: SpaceMonoBold;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
  `
  },
  monoRegular14: {
    xs: `
    font-family: SpaceMonoRegular;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: normal;
  `
  },
  monoRegular12: {
    xs: `
    font-family: SpaceMonoRegular;
    font-size: 0.75rem;
    line-height: 1.5;
    letter-spacing: normal;
  `
  }
};

export default variants as Variant;
