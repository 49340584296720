import { theme } from '@refract-ui/core';
import { refract, gfx } from '../shared';

const darkTheme = {
  name: 'dark',
  ...gfx,
  ...theme({
    ...refract,
    colors: { green: '#7EF39F', red: '#e94a4a' },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: theme?.colors?.black,
      fg: theme?.colors?.white,
      primary: '#333333',
      secondary: theme?.colors?.yellow,
      success: theme?.colors?.green,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
};

export default darkTheme;
