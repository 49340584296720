import { theme } from '@refract-ui/core';
import { DefaultTheme } from 'styled-components';
import { refract, gfx } from '../shared';

const starfaceTheme = {
  name: 'starface',
  ...gfx,
  ...theme({
    ...refract,
    colors: { yellow: '#FDFF00' },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: theme?.colors?.yellow,
      fg: theme?.colors?.black,
      primary: theme?.colors?.white,
      secondary: theme?.colors?.gray,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
} as DefaultTheme;

export default starfaceTheme;
