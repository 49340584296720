import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

interface ChipSelectProps {
  name: string;
  label?: string;
  options: { value: string; label: string }[];
}

export const Container = styled.div`
  display: grid;
  gap: 1rem;
`;

export const Label = styled.label`
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.5);
  font-family: SpaceGroteskLight;
  font-weight: 300;
`;

export const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
`;

export const Pill = styled.button<{ isSelected: boolean }>`
  border: 1px solid #ccc;
  background-color: ${props => (props.isSelected ? '#fff' : 'transparent')};
  color: ${props => (props.isSelected ? '#000' : '#fff')};
  text-transform: uppercase;
  border-radius: 5.875rem;
  font-weight: 300;
  font-family: SpaceGroteskLight;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: ${props => (props.isSelected ? '#DEDEDE' : '#444')};
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }

  ${({ theme: { mq } }) => mq.lg`
    font-size: 1.125rem;
    padding: 0.75rem 2rem;
  `}
`;

const ChipSelect: React.FC<ChipSelectProps> = ({ label, name, options }) => {
  const { control, getValues } = useFormContext();
  const {
    field: { onChange }
  } = useController({ name, control });
  const value = getValues(name);

  const toggleValue = (selectedValue: string) => {
    const newValue = value?.includes(selectedValue)
      ? value?.filter((v: string) => v !== selectedValue)
      : [...(value ?? []), selectedValue];
    onChange(newValue);
  };

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <PillContainer>
        {options.map(option => (
          <Pill
            key={option.value}
            isSelected={value?.includes(option?.value)}
            onClick={() => toggleValue(option?.value)}
            type="button"
          >
            {option.label}
          </Pill>
        ))}
      </PillContainer>
    </Container>
  );
};

export default ChipSelect;
