import React, { createContext, useContext, useState } from 'react';

interface ContactModalContextType {
  contactModalOpen: boolean;
  showContactModal: () => void;
}

const ContactModalContext = createContext<ContactModalContextType | undefined>(undefined);

export const useContactModal = () => {
  const context = useContext(ContactModalContext);
  if (!context) {
    throw new Error('useContactModal must be used within a ContactModalProvider');
  }
  return context;
};

const ContactModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const showContactModal = () => {
    setContactModalOpen(prev => !prev);
  };
  return (
    <ContactModalContext.Provider value={{ contactModalOpen, showContactModal }}>
      {children}
    </ContactModalContext.Provider>
  );
};
export default ContactModalProvider;
