import { css, type DefaultTheme } from 'styled-components';
import { SpacingMixin } from './paddingTypes';

export const SIZES = ['no', 'xs', 'sm', 'md', 'lg'] as const;

type Size = typeof SIZES[number];

interface BlockPadV {
  pt?: Size;
  pb?: Size;
}
interface Breakpoint {
  no: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
}

interface MediaQueries {
  mq: {
    [key: string]: typeof css;
  };
}

interface Padding {
  pt: string;
  pb: string;
}

/* 
  This function takes in the cms padding overwrites(if there is any) and applies those, and if there isn't any then we use the defaultPT from the block code
*/
export const blockPadV = ({ pt: defaultPt = 'no', pb: defaultPb = 'no' }: BlockPadV = {}) => css`
  ${({ theme }) => {
    const cmsFields: { [key: string]: Padding } = theme?.gfx?.block?.settings?.padding || null;
    const { mq }: MediaQueries = theme;
    return Object.entries(theme.gfx.block.padding.v).map(([key, value]) => {
      const bp = value as Breakpoint;
      const cmsFieldsAtBp = cmsFields?.[key] || {
        pt: defaultPt,
        pb: defaultPb
      };

      if (!cmsFieldsAtBp) return null;

      const ptIdx = (cmsFieldsAtBp?.pt as Size) || defaultPt;
      const pbIdx = (cmsFieldsAtBp?.pb as Size) || defaultPb;
      const bpStyles = `
          padding-top: ${bp?.[ptIdx]}rem;
          padding-bottom: ${bp?.[pbIdx]}rem;
      `;
      return mq[key]`${bpStyles}`;
    });
  }}
`;

export const blockPadH = () => css`
  ${({ theme }) => css`
    padding-left: ${theme.gfx.block.padding.h.xs}rem;
    padding-right: ${theme.gfx.block.padding.h.xs}rem;
  `}
`;

export const blockW = (defaultW: Size = 'lg') => css`
  ${({ theme }) => {
    const { maxW } = theme?.gfx?.block?.settings || {};
    const maxWIdx = SIZES.indexOf((maxW as Size) || defaultW);

    return (
      maxWIdx &&
      css`
        margin: 0 auto;
        max-width: ${theme.gfx.block.widths[maxWIdx]}px;

        ${theme.mq.xxl`
          max-width: 100%;
        `}
        @media (min-width: 1440px) {
          max-width: 1440px;
        }
        @media (min-width: 1864px) {
          max-width: 80%;
        }
      `
    );
  }}
`;

const layoutAbbreviations = {
  t: ['top'],
  b: ['bottom'],
  v: ['top', 'bottom'],
  l: ['left'],
  r: ['right'],
  h: ['left', 'right'],
  a: ['top', 'right', 'bottom', 'left']
};

type layoutAbbreviationsKeys = keyof typeof layoutAbbreviations;

// component level padding and margin mixins
const processSettings = (
  cssProperty: string,
  defaultSpacing: number,
  direction: layoutAbbreviationsKeys,
  overrides: SpacingMixin = {}
) => {
  const directionArr = layoutAbbreviations[direction];

  return css`
    ${({ theme }) => {
      const breakpointKeys = Object.keys(
        theme.breakpoints
      ) as (keyof DefaultTheme['breakpoints'])[];
      let spacing = theme.gfx.spacing.xs;
      let override = false;

      const settings = breakpointKeys?.reduce(
        (memo, key) => {
          const spacingKey = key as keyof DefaultTheme['gfx']['spacing'];
          if (theme.gfx.spacing[spacingKey]) {
            spacing = theme.gfx.spacing[spacingKey];
            if (!override) {
              memo[key] = spacing[defaultSpacing];
            }
          }
          if (overrides[key] !== undefined) {
            override = true;
            const spacingIdx = overrides[key] as typeof spacing[number];
            memo[key] = spacing[spacingIdx];
          }
          return memo;
        },
        {} as {
          [p in keyof DefaultTheme['breakpoints']]: number;
        }
      );

      return css`
        ${breakpointKeys.map(key => {
          const spacingKey = key as keyof typeof settings;

          if (settings[spacingKey] === undefined) {
            return undefined;
          }

          return theme.mq[key]`
            ${directionArr.map(
              direction => css`
                && {
                  ${cssProperty}-${direction}: ${settings[spacingKey]}rem;
                }
              `
            )}
          `;
        })}
      `;
    }}
  `;
};

export const pt = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('padding', defaultSpacing, 't', settings);

export const pb = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('padding', defaultSpacing, 'b', settings);

export const pl = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('padding', defaultSpacing, 'l', settings);

export const pr = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('padding', defaultSpacing, 'r', settings);

export const pv = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('padding', defaultSpacing, 'v', settings);

export const ph = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('padding', defaultSpacing, 'h', settings);

export const p = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('padding', defaultSpacing, 'a', settings);

export const mt = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('margin', defaultSpacing, 't', settings);

export const mb = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('margin', defaultSpacing, 'b', settings);

export const ml = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('margin', defaultSpacing, 'l', settings);

export const mr = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('margin', defaultSpacing, 'r', settings);

export const mv = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('margin', defaultSpacing, 'v', settings);

export const mh = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('margin', defaultSpacing, 'h', settings);

export const m = (defaultSpacing: number, settings?: SpacingMixin) =>
  processSettings('margin', defaultSpacing, 'a', settings);
