import React, { useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import { animated, useSpringValue } from '@react-spring/web';
import Image from 'next/image';
import styled from 'styled-components';

const Container = styled(animated.div)`
  position: fixed;
  display: grid;
  align-items: stretch;
  justify-content: stretch;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  overflow-y: auto;
`;

const Screen = styled.div`
  background: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const Dialog = styled.div`
  display: grid;
  position: relative;
  z-index: 302;
  align-self: stretch;
  justify-self: stretch;
  align-content: center;
`;

const Body = styled.div``;

const CloseButton = styled.button`
  position: fixed;
  top: 1rem;
  right: 1rem;
  border: 0;
  outline: 0;
  background: transparent;
`;

interface ModalProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ open, close, children }) => {
  const show = useSpringValue(0);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (open) {
      show.start(1);
    } else {
      show.start(0);
    }
  }, [open, show]);

  if (!mounted) {
    return null;
  }

  return (
    <Portal node={typeof document !== 'undefined' ? document.getElementById('__next') : null}>
      <Container
        style={{
          opacity: show,
          display: show.to(val => (val > 0 ? 'grid' : 'none'))
        }}
      >
        <Screen />
        <Dialog>
          <CloseButton onClick={close}>
            <Image priority src="/svg/close.svg" width={30} height={30} alt="Open Menu" />
          </CloseButton>
          <Body>{children}</Body>
        </Dialog>
      </Container>
    </Portal>
  );
};

export default Modal;
