import React from 'react';
import { css } from 'styled-components';
import FontLink from 'components/FontLink';

const allFonts = [
  'Roobert-Bold',
  'Roobert-Light',
  'Roobert-Medium',
  'Roobert-Regular',
  'Roobert-SemiBold',
  'SpaceMonoBold',
  'SpaceMonoItalic',
  'SpaceMonoBoldItalic',
  'SpaceMonoRegular'
];

export const FontDeclarations = css`
  ${allFonts.map(font => {
    const path = `/fonts/${font}.`;
    return css`
      @font-face {
        font-family: ${font};
        src: url('${path}woff') format('woff'), url('${path}woff2') format('woff2'),
          url('${path}ttf') format('truetype');
        font-display: swap;
        font-style: normal;
        font-weight: 400;
        font-stretch: normal;
      }
    `;
  })};
`;

const FontPreload: React.FC = () => (
  <>
    <FontLink key="/fonts/Roobert-Light.woff" href="/fonts/Roobert-Light.woff" type="woff" />
    <FontLink key="/fonts/Roobert-SemiBold.woff" href="/fonts/Roobert-SemiBold.woff" type="woff" />
    <FontLink key="/fonts/Roobert-Regular.woff" href="/fonts/Roobert-Regular.woff" type="woff" />
    <FontLink key="/fonts/Roobert-Medium.woff" href="/fonts/Roobert-Medium.woff" type="woff" />
    <FontLink key="/fonts/Roobert-Light.woff2" href="/fonts/Roobert-Light.woff2" type="woff2" />
    <FontLink
      key="/fonts/Roobert-SemiBold.woff2"
      href="/fonts/Roobert-SemiBold.woff2"
      type="woff2"
    />
    <FontLink key="/fonts/Roobert-Regular.woff2" href="/fonts/Roobert-Regular.woff2" type="woff2" />
    <FontLink key="/fonts/Roobert-Medium.woff2" href="/fonts/Roobert-Medium.woff2" type="woff2" />
    <FontLink key="/fonts/SpaceMonoRegular.ttf" href="/fonts/SpaceMonoRegular.ttf" type="ttf" />
    <FontLink key="/fonts/SpaceMonoBold.ttf" href="/fonts/SpaceMonoBold.ttf" type="ttf" />
    <link rel="stylesheet" href="/styles.css" />
  </>
);

export default React.memo(FontPreload);
