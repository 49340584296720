import { theme } from '@refract-ui/core';
import { DefaultTheme } from 'styled-components';
import { refract, gfx } from '../shared';

const figoTheme = {
  name: 'figo',
  ...gfx,
  ...theme({
    ...refract,
    colors: {
      orange: '#FAEFE2',
      blue: '#95E6E2'
    },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: '#FBF6F1',
      fg: theme?.colors?.black,
      primary: theme?.colors?.blue,
      secondary: theme?.colors?.orange,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
};

export default figoTheme;

export const figoDark = {
  name: 'figo',
  ...gfx,
  ...theme({
    ...refract,
    colors: {
      orange: '#FAEFE2',
      blue: '#95E6E2'
    },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: theme?.colors?.blue,
      fg: theme?.colors?.black,
      primary: '#01313D',
      secondary: theme?.colors?.orange,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
} as DefaultTheme;
