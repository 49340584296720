import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import variants from '../../theme/font-dictionary';

const Paragraph = styled.p<{ styleObj: StyleObject }>`
  ${({ styleObj, theme }) => css`
    color: ${theme.themeColors.fg};
    ${styleObj.xs}
  `}

  ${({ styleObj, theme }) =>
    styleObj.sm &&
    theme.mq.sm`
      ${css`
        ${styleObj.sm}
      `}
  `};

  ${({ styleObj, theme }) =>
    styleObj.md &&
    theme.mq.md`
      ${css`
        ${styleObj.md}
      `}
  `};

  ${({ styleObj, theme }) =>
    styleObj.lg &&
    theme.mq.lg`
      ${css`
        ${styleObj.lg}
      `}
  `};

  ${({ styleObj, theme }) =>
    styleObj.xl &&
    theme.mq.xl`
      ${css`
        ${styleObj.xl}
      `}
  `};

  ${({ styleObj, theme }) =>
    styleObj.xxl &&
    theme.mq.xxl`
      ${css`
        ${styleObj.xxl}
      `}
  `};

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
    &:hover {
      opacity: 50%;
    }
  }
`;

interface StyleObject {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}

export type FormattedText = {
  pFormat: string;
  children: ReactNode;
};

const FormattedText: React.FC<FormattedText> = props => {
  const { pFormat, children } = props;
  // pFormat is one of the following:
  // sansRegular64
  // sansRegular52
  // sansMedium42
  // sansRegular30 (default)
  // sansRegular24
  // sansBold24
  // sansRegular18
  // sansBold18
  // sansRegular12
  // monoRegular24
  // monoRegular18
  // monoRegular16
  // monoBold16
  // monoRegular14
  //
  // formula for naming is: font + weight + md px font size

  return (
    <Paragraph styleObj={variants[pFormat || 'default']} {...props}>
      {children}
    </Paragraph>
  );
};

export default FormattedText;
