import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import FieldWrapper from '../FieldWrapper';

const InputText = styled.textarea`
  border: none;
  outline: none;
  box-shadow: none;
  background: rgba(0, 0, 0, 0);
  margin: 29px 0 0 0;
  padding: 0;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ theme }) => theme.themeColors.fg};

  // I can't seem to disable autofill or change the font-size/font-family of suggestions
  :-webkit-autofill {
    // fix for autofill text's color
    -webkit-text-fill-color: ${({ theme }) => theme.themeColors.fg};
    // fix for autofill background color (need it transparent)
    -webkit-background-clip: text;
  }

  font-family: SpaceGroteskLight;
  font-size: 1.125rem;
  line-height: 26px;
  height: 84px;
  ${({ theme }) => theme.mq.lg`
    font-size: 1.5rem;
    line-height: 34px;
    height: 124px;
    margin-top: 43px;
    padding: 0;
  `}
`;

interface ITextInput {
  fieldName: string;
  placeholder: string;
}

const TextArea: React.FC<ITextInput> = ({ fieldName, placeholder }: ITextInput) => {
  const { formState, register, getValues } = useFormContext();
  const value = getValues(fieldName);
  return (
    <FieldWrapper
      error={formState?.errors[fieldName]}
      filled={!!value}
      isTouched={formState?.touchedFields[fieldName]}
      name={fieldName}
      placeholder={placeholder}
    >
      <InputText id={fieldName} {...register(fieldName)} />
    </FieldWrapper>
  );
};

export default TextArea;
