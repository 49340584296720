import React from 'react';
import { Icons, IconNames } from './IconList';
import { useTheme } from 'styled-components';
import Image from 'next/image';

interface IIcon {
  className?: string;
  viewBox?: string;
  role?: string;
  size?: '16' | '24' | '32' | '40' | '72';
  color?: string;
  name: IconNames;
  alt?: string;
}

const Icon: React.FC<IIcon> = ({ viewBox, size, name, color, className, alt }) => {
  const theme = useTheme();
  if (!Icons) {
    // test workaround
    return <div></div>;
  }
  if (!Icons[name]) {
    return null;
  }
  const { component, defaults } = Icons[name];

  /* If its an image and not an svg */
  if (component?.src) {
    return <Image src={component?.src} alt="logo" {...component}></Image>;
  }

  const options = {
    color: color || theme.themeColors.fg,
    width: size || defaults.width,
    height: size || defaults.height,
    viewBox: viewBox || defaults?.viewBox,
    alt: alt || defaults.alt,
    className
  };
  return <>{React.createElement(component, options)}</>;
};

export default Icon;
