import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import GlobalStyles from 'theme/GlobalStyles';
import FontPreload from 'components/FontPreload';
import ContactForm from 'components/ContactForm';
import ContactModalProvider from 'context/Contact';
import reporter from '../lib/analytics';
import { getVisitorSource } from '@analytics/visitor-source';

function App({ Component, pageProps }: AppProps) {
  // track source of traffic
  useEffect(() => {
    try {
      const visitorSource = getVisitorSource({
        referrer: document.referrer,
        currentUrl: window.location.href,
        mapper: null
      });
      reporter?.track('TrafficSource', visitorSource);
    } catch (err) {
      console.log('source error.');
    }
  }, []);

  return (
    <React.StrictMode>
      <FontPreload />
      <GlobalStyles>
        <ContactModalProvider>
          <Component {...pageProps} />
          <ContactForm />
        </ContactModalProvider>
      </GlobalStyles>
    </React.StrictMode>
  );
}

export default App;
