import { DefaultTheme } from 'styled-components';
import { theme } from '@refract-ui/core';
import { refract, gfx } from '../shared';

const lightTheme = {
  name: 'light',
  ...gfx,
  ...theme({
    ...refract,
    colors: {
      red: '#e94a4a'
    },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: theme?.colors?.white,
      fg: theme?.colors?.black,
      primary: theme?.colors?.cyan,
      secondary: theme?.colors?.yellow,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
} as DefaultTheme;

export default lightTheme;
