import dark from './variants/dark';
import light from './variants/light';
import goodfriend from './variants/goodfriend';
import plume, { plumeSlider, plumeLight } from './variants/plume';
import cgr from './variants/cgr';
import starface from './variants/starface';
import figo, { figoDark } from './variants/figo';

const themes = {
  dark,
  light,
  goodfriend,
  plume,
  cgr,
  figo,
  figoDark,
  plumeSlider,
  plumeLight,
  starface
};

export default themes;
