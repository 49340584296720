import Logo from './../../public/icons/logo.svg';
import Menu from './../../public/icons/menu.svg';
import SmallMenu from './../../public/icons/small-menu.svg';
import Close from './../../public/icons/close.svg';
import Hydrant from './../../public/icons/hydrant-colored.png';
import Plant from './../../public/icons/plant.svg';
import ArrayRight from './../../public/icons/arrow-right.svg';
import ArrayLeft from './../../public/icons/arrow-left.svg';
import Flower from './../../public/icons/flower-colored.png';
import Pug from './../../public/icons/pug-colored.png';
import Shopify from './../../public/icons/shopify.svg';
import Stripe from './../../public/icons/stripe.svg';
import Zendesk from './../../public/icons/zendesk.svg';
import Heap from './../../public/icons/heap.svg';
import Segment from './../../public/icons/segment.svg';
import GoogleAnalytics from './../../public/icons/google_analytics.svg';
import Nextjs from './../../public/icons/nextjs.svg';
import Contentful from './../../public/icons/contentful.svg';
import Vercel from './../../public/icons/vercel.svg';
import Klaviyo from './../../public/icons/klaviyo.svg';
import GoogleOptimize from './../../public/icons/google_optimize.svg';
import RubyOnRails from './../../public/icons/ruby_on_rails.svg';
import Heroku from './../../public/icons/heroku.svg';
import ReactNative from './../../public/icons/react.svg';
import DTHREE from './../../public/icons/d3.svg';
import Threejs from './../../public/icons/threejs.svg';
import PostgreSQL from './../../public/icons/postgresql.svg';
import Mars from './../../public/icons/mars.svg';
import K from './../../public/icons/k.svg';
import MiracleGro from './../../public/icons/miracle-gro.svg';
import SpectrumHealth from './../../public/icons/spectrum-health.svg';
import IBM from './../../public/icons/ibm.svg';
import Tovala from './../../public/icons/tovala.svg';
import Plume from './../../public/icons/plume.svg';
import Starface from './../../public/icons/starface.svg';
import Superplastic from './../../public/icons/superplastic.svg';
import SkinnyArrow from './../../public/icons/skinny-arrow.svg';
import StarfaceTraffic from './../../public/icons/starface-traffic.png';
import StyledComponents from './../../public/icons/styled-components.svg';
import GraphQL from './../../public/icons/graphql.svg';
import Apollo from './../../public/icons/apollo.svg';
import Storybook from './../../public/icons/storybook.svg';
import Zod from './../../public/icons/zod.svg';
import Lighthouse from './../../public/icons/lighthouse.svg';
import Webpack from './../../public/icons/webpack.svg';
import Eslint from './../../public/icons/eslint.svg';
import Vitest from './../../public/icons/vitest.svg';
import Playwright from './../../public/icons/playwright.svg';
import Cobalt from './../../public/icons/cobalt.svg';
import Axe from './../../public/icons/axe.svg';

// -- How to add icons --
// 1. Bring svg into 'public/icons'
// 2. Import svg component
// 3. Add key/value to Icons obj
//   3.a. Define component
//   3.b. Define defaults (width, height, viewBox, role, alt)

export const Icons = {
  Logo: {
    component: Logo,
    defaults: {
      width: '70',
      height: '70',
      viewBox: '0 0 1000 1000',
      alt: 'Graveflex logo'
    }
  },
  Close: {
    component: Close,
    defaults: {
      width: '40',
      height: '40',
      viewBox: '0 0 40 40',
      alt: 'Close icon'
    }
  },
  Menu: {
    component: Menu,
    defaults: {
      width: '40',
      height: '40',
      viewBox: '0 0 40 40',
      alt: 'Menu icon'
    }
  },
  SmallMenu: {
    component: SmallMenu,
    defaults: {
      width: '20',
      height: '20',
      viewBox: '0 0 20 10',
      alt: 'Small Menu icon'
    }
  },
  Pug: {
    component: Pug,
    defaults: {
      width: '125',
      height: '94',
      viewBox: '0 0 125 94',
      alt: 'Pug icon'
    }
  },
  Hydrant: {
    component: Hydrant,
    defaults: {
      width: '80',
      height: '121',
      viewBox: '0 0 80 121',
      alt: 'Hydrant icon'
    }
  },
  Plant: {
    component: Plant,
    defaults: {
      width: '98',
      height: '169',
      viewBox: '0 0 98 169',
      alt: 'Plant icon'
    }
  },
  ArrowLeft: {
    component: ArrayLeft,
    defaults: {
      width: '8',
      height: '12',
      viewBox: '0 0 8 12',
      alt: 'Arrow Left icon'
    }
  },
  ArrowRight: {
    component: ArrayRight,
    defaults: {
      width: '8',
      height: '12',
      viewBox: '0 0 8 12',
      alt: 'Arrow Right icon'
    }
  },
  Flower: {
    component: Flower,
    defaults: {
      width: '98',
      height: '169',
      viewBox: '0 0 98 169',
      alt: 'Flower icon'
    }
  },
  Shopify: {
    component: Shopify,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Shopify icon'
    }
  },
  Stripe: {
    component: Stripe,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Stripe icon'
    }
  },
  Zendesk: {
    component: Zendesk,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Zendesk icon'
    }
  },
  Heap: {
    component: Heap,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Heap icon'
    }
  },
  Segment: {
    component: Segment,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Segment icon'
    }
  },
  GoogleAnalytics: {
    component: GoogleAnalytics,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Google Analytics icon'
    }
  },
  Nextjs: {
    component: Nextjs,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Nextjs icon'
    }
  },
  Contentful: {
    component: Contentful,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Contentful icon'
    }
  },
  Vercel: {
    component: Vercel,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Vercel icon'
    }
  },
  Klaviyo: {
    component: Klaviyo,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Klaviyo icon'
    }
  },
  GoogleOptimize: {
    component: GoogleOptimize,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Google Optimize icon'
    }
  },
  RubyOnRails: {
    component: RubyOnRails,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Ruby On Rails icon'
    }
  },
  Heroku: {
    component: Heroku,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Heroku icon'
    }
  },
  ReactNative: {
    component: ReactNative,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'React Native icon'
    }
  },
  DTHREE: {
    component: DTHREE,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'DTHREE icon'
    }
  },
  Threejs: {
    component: Threejs,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'Threejs icon'
    }
  },
  PostgreSQL: {
    component: PostgreSQL,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'PostgreSQL icon'
    }
  },
  Mars: {
    component: Mars,
    defaults: {
      width: '136',
      height: '39',
      viewBox: '0 0 136 39',
      alt: 'Mars icon'
    }
  },
  K: {
    component: K,
    defaults: {
      width: '43',
      height: '58',
      viewBox: '0 0 43 58',
      alt: 'K icon'
    }
  },
  MiracleGro: {
    component: MiracleGro,
    defaults: {
      width: '148',
      height: '42',
      viewBox: '0 0 148 42',
      alt: 'MiracleGro icon'
    }
  },
  SpectrumHealth: {
    component: SpectrumHealth,
    defaults: {
      width: '170',
      height: '58',
      viewBox: '0 0 170 58',
      alt: 'SpectrumHealth icon'
    }
  },
  IBM: {
    component: IBM,
    defaults: {
      width: '108',
      height: '43',
      viewBox: '0 0 108 43',
      alt: 'IBM icon'
    }
  },
  Tovala: {
    component: Tovala,
    defaults: {
      width: '174',
      height: '24',
      viewBox: '0 0 174 24',
      alt: 'Tovala icon'
    }
  },
  Plume: {
    component: Plume,
    defaults: {
      width: '151',
      height: '38',
      viewBox: '0 0 151 38',
      alt: 'Plume icon'
    }
  },
  Starface: {
    component: Starface,
    defaults: {
      width: '192',
      height: '28',
      viewBox: '0 0 192 28',
      alt: 'Starface icon'
    }
  },
  Superplastic: {
    component: Superplastic,
    defaults: {
      width: '187',
      height: '24',
      viewBox: '0 0 187 24',
      alt: 'Superplastic icon'
    }
  },
  SkinnyArrow: {
    component: SkinnyArrow,
    defaults: {
      width: '40',
      height: '40',
      viewBox: '2 7 40 28',
      alt: 'Superplastic icon'
    }
  },
  StarfaceTraffic: {
    component: StarfaceTraffic,
    defaults: {
      width: '331',
      height: '155',
      viewBox: '0 0 331 155',
      alt: 'Starface traffic icon'
    }
  },
  StyledComponents: {
    component: StyledComponents,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'StyledComponents icon'
    }
  },
  React: {
    component: ReactNative,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 60 60',
      alt: 'React icon'
    }
  },
  GraphQL: {
    component: GraphQL,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'GraphQL icon'
    }
  },
  Apollo: {
    component: Apollo,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Apollo icon'
    }
  },
  Storybook: {
    component: Storybook,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Storybook icon'
    }
  },
  Zod: {
    component: Zod,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Zod icon'
    }
  },
  Lighthouse: {
    component: Lighthouse,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Lighthouse icon'
    }
  },
  Webpack: {
    component: Webpack,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Webpack icon'
    }
  },
  Eslint: {
    component: Eslint,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Eslint icon'
    }
  },
  Vitest: {
    component: Vitest,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Vitest icon'
    }
  },
  Playwright: {
    component: Playwright,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Playwright icon'
    }
  },
  Cobalt: {
    component: Cobalt,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Cobalt icon'
    }
  },
  Axe: {
    component: Axe,
    defaults: {
      width: '60',
      height: '60',
      viewBox: '0 0 80 80',
      alt: 'Axe icon'
    }
  }

  // ... add more icons here...
} as const;

export const ToolIcons = Object.keys(Icons)
  .filter(
    i =>
      ![
        'Logo',
        'Close',
        'Menu',
        'SmallMenu',
        'Pug',
        'Hydrant',
        'Plant',
        'ArrowLeft',
        'ArrowRight',
        'Flower',
        'Plume',
        'Starface',
        'SkinnyArrow',
        'Superplastic',
        'StarfaceTraffic',
        'SpectrumHealth',
        'Mars',
        'MiracleGro',
        'Tovala',
        'IBM'
      ].includes(i)
  )
  .map(i => ({
    label: i,
    value: i
  }))
  .sort((a, b) => a.value.localeCompare(b.value));

export type IconNames = keyof typeof Icons;
