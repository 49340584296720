import React from 'react';

interface FontLink {
  type: string;
  href: string;
}

const FontLink: React.FC<FontLink> = ({ type, href }) => {
  return <link rel="preload" href={`${href}`} type={`font/${type}`} as="font" crossOrigin="true" />;
};

export default FontLink;
