import React from 'react';
import styled from 'styled-components';

interface IWrapper {
  filled: boolean;
  error: boolean;
}

const Wrapper = styled.div<IWrapper>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid
    ${({ theme, error }) => (error ? theme.themeColors.danger : theme.themeColorOpacities.fg50)};


  ${({ theme: { mq }, filled }) => mq.ltLg`
    :focus-within label {
      transform: translate(0, 8px) scale(0.667);
    }

    ${
      filled &&
      `
      label {
        transform: translate(0, 8px) scale(0.667);
      }
    `
    };
  `};

  ${({ theme: { mq }, filled }) => mq.lg`
      :focus-within label {
        transform: translate(0, 13px) scale(0.8);
      }

      ${
        filled &&
        `
          label {
            transform: translate(0, 13px) scale(0.8);
          }
        `
      };
  `};
  }
`;

const FieldName = styled.label`
  position: absolute;
  pointer-events: none;
  transform: translate(0, 29px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ theme }) => theme.themeColorOpacities.fg50};
  font-family: SpaceGroteskLight;
  font-size: 1.125rem;
  line-height: 26px;
  ${({ theme }) => theme.mq.lg`
    font-size: 1.5rem;
    line-height: 34px;
    transform: translate(0, 45px) scale(1);
  `}
  ${({ theme }) => theme.gfx.mixins.mb(0)}
`;

const FieldError = styled.span`
  color: ${({ theme }) => theme.themeColors.danger};
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 14px;
  font-size: 0.8rem;
  line-height: 1;
  font-size: 0.75rem;
  ${({ theme }) => theme.mq.lg`
    font-size: 1.125rem;
  `}
  ${({ theme }) => theme.gfx.mixins.mb(0)}
`;

interface IFieldWrapper {
  children: React.ReactNode;
  filled: boolean;
  error: any;
  isTouched: boolean;
  name: string;
  placeholder: string;
}

const FieldWrapper: React.FC<IFieldWrapper> = ({
  children,
  error,
  filled,
  isTouched,
  name,
  placeholder
}: IFieldWrapper) => {
  return (
    <Wrapper error={error} filled={filled || !!error}>
      <FieldName htmlFor={name}>{placeholder}</FieldName>
      {isTouched && error?.message && <FieldError>{error.message}</FieldError>}
      {children}
    </Wrapper>
  );
};

export default FieldWrapper;
