import { pt, pb, pl, pr, pv, ph, p, mt, mb, ml, mr, mv, mh, m } from './mixins/padding';
import variants from './font-dictionary';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';

const varCopy = cloneDeep(variants);

const cssToObject = (input: string): Record<string, unknown> => {
  const attributes = input.trim().replace(/\s/g, '').split(';').filter(Boolean);

  return attributes.reduce((result, attribute) => {
    const [key, ...valueParts] = attribute.split(':');
    const transformStyleKey = (key: string): string => {
      switch (key) {
        case 'fontSize':
          return 'size';
        case 'fontFamily':
          return 'stack';
        case 'fontWeight':
          return 'weight';
        default:
          return key;
      }
    };

    const styleKey = transformStyleKey(camelCase(key));
    result[styleKey] = valueParts.join(':');
    return result;
  }, {} as Record<string, unknown>);
};

Object.keys(varCopy).forEach(key => {
  if (varCopy[key]) {
    varCopy[key].xs = cssToObject(varCopy[key].xs);
    if (varCopy[key].sm) {
      varCopy[key].sm = cssToObject(varCopy[key].sm);
    }
    if (varCopy[key].md) {
      varCopy[key].md = cssToObject(varCopy[key].md);
    }
  }
});

const { sansRegular64, sansRegular52, sansRegular36, sansRegular30, sansRegular24, sansRegular12 } =
  varCopy;

export const refract = {
  fontStacks: {
    mono: ['spaceMonoRegular'],
    sans: ['spaceGroteskRegular'],
    sansMedium: ['spaceGroteskMedium']
  },
  fontVariants: ({ defaults, theme }: any) => ({
    ...defaults,
    heading: {
      ...defaults.heading,
      stack: theme.fontStacks.sans
    }
  }),
  globalStyles: {
    xs: {
      ul: {
        mb: '0'
      },
      h1: sansRegular64.xs,
      h2: sansRegular52.xs,
      h3: sansRegular52.xs,
      h4: sansRegular36.xs,
      h5: sansRegular30.xs,
      h6: sansRegular24.xs,
      large: sansRegular30.xs,
      small: sansRegular12.xs,
      button: {
        weight: 600
      }
    },
    sm: {
      h1: sansRegular64.sm,
      h2: sansRegular52.sm
    },
    md: {
      h1: sansRegular64.md,
      h2: sansRegular52.md,
      h3: sansRegular52.md,
      h4: sansRegular36.md,
      h5: sansRegular30.md,
      h6: sansRegular24.md
    }
  }
};

export const gfx = {
  gfx: {
    block: {
      widths: [576, 768, 992, 1200, 1400] as const,
      padding: {
        h: {
          xs: 1.5
        },
        v: {
          xs: {
            no: 0,
            xs: 2,
            sm: 2.5,
            md: 3.125,
            lg: 3.75,
            xl: 6.25
          },
          sm: {
            no: 0,
            xs: 2,
            sm: 2.5,
            md: 3.125,
            lg: 3.75,
            xl: 6.25
          },
          md: {
            no: 0,
            xs: 2.5,
            sm: 3.125,
            md: 4.375,
            lg: 5.625,
            xl: 10
          },
          lg: {
            no: 0,
            xs: 2.5,
            sm: 3.125,
            md: 4.375,
            lg: 5.625,
            xl: 10
          },
          xl: {
            no: 0,
            xs: 2.5,
            sm: 3.125,
            md: 4.375,
            lg: 5.625,
            xl: 10
          },
          xxl: {
            no: 0,
            xs: 2.5,
            sm: 3.125,
            md: 4.375,
            lg: 5.625,
            xl: 10
          }
        }
      }
    },
    // these global spacing values are in rems :)
    // desktop numbers from designs in px --> 10, 16, 24, 50, 70, 94, 172
    // mobile numbers from designs in px --> 8, 10, 12, 16, 24, 50, 86
    spacing: {
      xs: [0, 0.5, 0.625, 0.75, 1, 1.5, 3.125, 5.375] as const,
      md: [0, 0.625, 1, 1.5, 3.125, 4.315, 5.785, 10.75] as const
    },
    mixins: {
      pt,
      pb,
      pl,
      pr,
      pv,
      ph,
      p,
      mt,
      mb,
      ml,
      mr,
      mv,
      mh,
      m
    }
  }
};
