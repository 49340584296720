import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Container, Label, PillContainer, Pill } from 'components/fields/ChipSelect';

interface ChipSelectProps {
  name: string;
  label?: string;
  options: { value: string; label: string }[];
}

const ChipSelect: React.FC<ChipSelectProps> = ({ name, label, options }) => {
  const { control, getValues } = useFormContext();
  const {
    field: { onChange }
  } = useController({ name, control });
  const value = getValues(name);

  return (
    <Container>
      <Label>{label}</Label>
      <PillContainer>
        {options.map(option => (
          <Pill
            key={option.value}
            isSelected={value && value === option.value}
            onClick={() => onChange(option.value)}
            type="button"
          >
            {option.label}
          </Pill>
        ))}
      </PillContainer>
    </Container>
  );
};

export default ChipSelect;
