import { theme } from '@refract-ui/core';
import { DefaultTheme } from 'styled-components';
import { refract, gfx } from '../shared';

const goodfriendTheme = {
  name: 'goodfriend',
  ...gfx,
  ...theme({
    ...refract,
    colors: {
      orange: '#FF7817',
      teal: '#B6EAEB'
    },
    themeColors: ({ defaults, theme }) => ({
      ...defaults,
      bg: theme?.colors?.white,
      fg: theme?.colors?.black,
      primary: theme?.colors?.orange,
      secondary: theme?.colors?.teal,
      danger: theme?.colors?.red,
      warning: theme?.colors?.yellow
    })
  })
} as DefaultTheme;

export default goodfriendTheme;
